export const settings = loadSettings();


if (settings.needstorage) {
  delete settings.needstorage;
  saveSettings();
}


function loadSettings() {
  let string = localStorage.getItem('settings');
  if (string) {
    return JSON.parse(string);
  } else {
    return {
      "needstorage": true,
      "purge": "ask"
    };
  }
}

export function saveSettings() {
  localStorage.setItem('settings', JSON.stringify(settings));
}
