export const texts_en = {
  'Storing data ({0} kB) to dropbox ({1}).': 'Storing data ({0} kB) to dropbox ({1}).',
  'Storing to dropbox failed. Trying again in {0} seconds': 'Storing to dropbox failed. Trying again in {0} seconds.',
  'Reading data from dropbox ({0}).': 'Reading data from dropbox ({0}).',
  'Loading Google Calendar {0}': 'Loading Google Calendar {0}.',
  'Cleared {0} todos deleted more than 4 weeks ago.': 'Cleared {0} todos deleted more than 4 weeks ago.',
  'Cleared {0} todos done more than 8 weeks ago.': 'Cleared {0} todos done more than 8 weeks ago.',
  'Nothing to clean up.': 'Nothing to clean up.',
  'Today': 'Today',
  'This Week': 'This Week',
  'Tomorrow': 'Tomorrow',
  'Yesterday': 'Yesterday',
  'Monday': 'Monday',
  'Tuesday': 'Tuesday',
  'Wednesday': 'Wednesday',
  'Thursday': 'Thursday',
  'Friday': 'Friday',
  'Saturday': 'Saturday',
  'Sunday': 'Sunday',
  'Jan': 'Jan',
  'Feb': 'Feb',
  'Mar': 'Mar',
  'Apr': 'Apr',
  'May': 'May',
  'Jun': 'Jun',
  'Jul': 'Jul',
  'Aug': 'Aug',
  'Sep': 'Sep',
  'Oct': 'Oct',
  'Nov': 'Nov',
  'Dec': 'Dec',
  'Mon': 'Mon',
  'Tue': 'Tue',
  'Wed': 'Wed',
  'Thu': 'Thu',
  'Fri': 'Fri',
  'Sat': 'Sat',
  'Sun': 'Sun',
  'CW': 'CW',
  'calendar week': 'calendar week',
  'January': 'January',
  'February': 'February',
  'March': 'March',
  'April': 'April',
  'June': 'June',
  'July': 'July',
  'August': 'August',
  'September': 'September',
  'October': 'October',
  'November': 'November',
  'December': 'December',
  'Timer has completed.': 'Timer has completed.',
  'settingsarea-experimental': 'Experimental',
  'setting-calendar-delete-button': 'Delete calendar events',
  'setting-weekly-overdue': 'Weekly overdue',
  'setting-delete-all': 'Delete all events',
  'setting-debug-prio': 'Debug mode priorities',
  'setting-debug-properties': 'Debug mode properties',
  'setting-essential-view': 'Main view',
  'setting-repeated-timers': 'Repeated alarms',
  'setting-auto-tags': 'Automatic tags',
  'settingsarea-connections': 'Connections',
  'settingsarea-debug': 'Debug',
  'settingsarea-behavior': 'Behavior',
  'setting-confetti': 'Confetti',
  'setting-purge': 'Purge Old Data',
  'setting-purge-ask': 'Ask',
  'setting-purge-disallow': 'No',
  'setting-purge-allow': 'Yes',
  'setting-default-duration': 'Default Duration',
  'setting-default-duration-': '0',
  'setting-dropbox': 'Dropbox',
  'setting-google-calendar': 'Google Calendar',
  'setting-o365-calendar': 'O365 Calendar',
  'setting-mobile-console': 'Mobile console',
  'week': 'week',
  'weeks': 'weeks',
  'day': 'day',
  'days': 'days',
  'weekdays': 'weekdays',
  'month': 'month',
  'months': 'months',
  'every': 'every',
  'every-day': 'every day',
  'every-weekday': 'every weekday',
  'every-week': 'every week',
  'every-month': 'every month'
};

export const ui_en = {
  '#today': texts_en.Today,
  '#tomorrow': texts_en.Tomorrow,
  '#monday': texts_en.Monday,
  '#tuesday': texts_en.Tuesday,
  '#wednesday': texts_en.Wednesday,
  '#thursday': texts_en.Thursday,
  '#friday': texts_en.Friday,
  '#saturday': texts_en.Saturday,
  '#sunday': texts_en.Sunday,
  '#overdue': 'Overdue',
  '#this-week': 'This Week',
  '#this-week-strict': 'This Week',
  '#next-week': 'Next Week',
  '#this-month': 'This Month',
  '#this-month-strict': 'This Month',
  '#this-year': 'This Year',
  '#this-year-strict': 'This Year',
  '#this-weekend': 'This Weekend',
  '#eventually': 'Eventually',
  '#preferences': 'Preferences',
  '#repeattimers': 'Repeated alarms',
  '#autotags': 'Automatic tags',
  '#week-left:title': 'Previous week',
  '#week-right:title': 'Next week',
  '#week-today:title': 'Today',
  '#to-search': 'Search',
  '#delete-all': 'Delete all',
  '#add-todo-button': 'New task',
  '#sync-save-button': 'Save',
  '#purge-todos-button': 'Clean up tasks',
  '#dropbox-login-button': 'Connect',
  '#dropbox-logout-button': 'Disconnect',
  '#google-login-button': 'Connect',
  '#google-logout-button': 'Disconnect',
  '#dropbox-file': 'Select file <small class="filename"></small>',
  '#google-calendar-list': 'Select calendar <small class="calendars"></small>',
  '#about-tio': 'About TIO',
  '#dropboxModalLabel': 'Connect Dropbox',
  '#dropboxModal .modal-body p:nth-child(1)': 'Connect your Dropbox so TIO can store all data in the folder <code>/apps/tio.one</code>.',
  '#dropboxModal .modal-body p:nth-child(2)': 'This way you have a backup of your data and can sync it between multiple devices.',
  '#dropboxModal .modal-body p:nth-child(3)': 'When you click "Connect", you will be redirected to the Dropbox page and asked for permission.',
  '#delete-series-modal-label': 'Delete series',
  '#delete-series-modal .modal-body .before': 'Task',
  '#delete-series-modal .modal-body .after': 'with repetitions delete:',
  '#delete-series-modal .modal-body option[value="future"]': 'Delete only this and future repetitions',
  '#delete-series-modal .modal-body option[value="all"]': 'Delete all repetitions',
  '#delete-series-modal .modal-footer .modal-submit': 'Delete series',
  '#dropbox-file-modal-label': 'Select Dropbox file',
  '#dropbox-file-modal .modal-body p span:nth-of-type(1)': 'Choose the filename for your TIO data.',
  '#dropbox-file-modal .modal-body p span:nth-of-type(2)': 'This way you can manage multiple todo lists.',
  '#dropbox-file-modal .modal-body p span:nth-of-type(3)': '(TIO files are stored under <code>/apps/tio.one</code>.)',
  '#dropbox-file-modal .modal-body label:nth-of-type(1)': 'Choose an existing name: <small>(and load the file)</small>',
  '#dropbox-file-modal .modal-body label:nth-of-type(2)': 'or take a new one: <small>(and save your tasks there)</small>',
  '#dropbox-file-modal .modal-footer .modal-submit': 'Select',
  '.modal-cancel': 'Cancel',
  '#connect-dropbox': 'Connect',
  '.i18n-day': 'per day',
  '.i18n-week': 'per week',
  '.i18n-month': 'per month',
  '.i18n-year': 'per year',
  '.i18n-days': 'days',
  '.i18n-weekdays': 'weekdays',
  '.i18n-weeks': 'weeks',
  '.i18n-months': 'months',
  '.i18n-years': 'years',
  '.i18n-one': 'one',
  '.i18n-two': 'two',
  '.i18n-three': 'three',
  '.i18n-four': 'four',
  '.i18n-five': 'five',
  '.i18n-six': 'six',
  '.i18n-duration': 'Duration',
  '.i18n-notes': 'Notes',
  '.i18n-due': 'Due',
  '.i18n-repeat-every': 'Repeat every',
  '.i18n-sync': 'Sync',
  '.i18n-sync-data': 'Syncing data...',
  '.i18n-loading': 'Loading...',
  '#invalid-feedback-duration': 'Enter duration as number and unit, e.g. <code>10m</code>',
  '#invalid-feedback-title': 'Please enter a title',
  '#invalid-feedback-time': 'Enter date as <code>yyyy-MM-dd</code> (year, month, day) and time as <code>HH:mm</code> (hours, minutes)',
  '#invalid-feedback-prio': 'Priority as max. three-digit, positive or negative number',
  '#titleHelp': 'Title, can contain Markdown:  <code>**</code>, <code>__</code>, <code>[]()</code>',
  '.i18n-close': 'Close',
  '.i18n-delete': 'Delete',
  '.i18n-delete-series': 'Delete series',
  '.i18n-edit-series': 'Edit series',
  '.i18n-submit': 'Submit',
  '.i18n-title': 'Title',
  '#addTodoModalLabel': 'New task',
  '#editTodoModalLabel': 'Edit task',
  '.todo-popover .edit': 'Edit',
  '.todo-popover .delete': 'Delete',
  '.todo-popover .to-today:title': 'Move to today',
  '.todo-popover .next-day:title': 'Move to next day',
  '.todo-popover .next-week:title': 'Move to next week',
  '.todo-popover .next-weekend:title': 'Move to next weekend',
  '.todo-popover .clone:title': 'Duplicate task',
  '.todo-popover .clone': 'Copy',
  '#editTodoNotes:placeholder': 'Any Markdown notes...',
  '#addTodoNotes:placeholder': 'Any Markdown notes...',
  '#resetTodo': 'Reset',
  '#purge-modal-label': 'Delete old data?',
  '#purge-modal .modal-body p:nth-of-type(1)': 'There are many tasks that have been completed or deleted for a long time. You probably don\'t need them anymore and they waste space.',
  '#purge-modal .modal-body p:nth-of-type(2)': 'Can we regularly remove such tasks (deleted 4 weeks ago or completed 8 weeks ago)?',
  '#purge-modal-submit': 'Remove',
  '.due-title': 'Due',
  '.duration-title': 'Duration',
  '.created-title': 'Created',
  '.done-title': 'Done',
  '.imported-title': 'Imported from',
  '.repeat-title': 'Repeat',
  '.repeat-was-title': 'Repeat was',
  '.original-created-title': 'Original created',
  '#intro-modal-label': 'TIO Planner',
  '#intro-modal .jumbotron .lead': '<b>TIO is a task planner<br/>on a weekly basis, offline first</b>',
  '#intro-modal .jumbotron li:nth-of-type(1)': 'All tasks of a week at a glance.',
  '#intro-modal .jumbotron li:nth-of-type(2)': 'Keep an eye on overdue tasks.',
  '#intro-modal .jumbotron li:nth-of-type(3)': 'All data stays in your browser.',
  '#intro-modal .jumbotron li:nth-of-type(4)': 'Format your text with Markdown.',
  '#intro-modal .jumbotron li:nth-of-type(5)': '<em>Optional</em>: Sync tasks to your cloud.',
  '#intro-modal .jumbotron li:nth-of-type(6)': '<em>Optional</em>: Integrate calendar events.',
  '#intro-modal .tourinfo': 'If you want, we will explain TIO\'s features to you when they first appear on the screen.',
  '#intro-modal-submit': 'Yes, I want a tour!',
  '#intro-modal-cancel': 'No, I don\'t need a tour.',
  '#unclear-sync-modal-submit': 'Local data',
  '#unclear-sync-modal-cancel': 'Remote data',
  '#preferences-modal-label': 'Preferences',
  '#preferences-modal p span': 'Turn features on or off:',
  '#preferences-modal-submit': 'Apply',
  '.tag-button.untagged': 'Untagged',
};